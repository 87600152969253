<script setup>
import { MenuItemComponent, LocaleChangerComponent } from '@/components';
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps(['items', 'parentid', 'path']);


function sanitize(s) {  
    if(s.indexOf("://")>0) {
        return s;
    }
    
    
    var a= s.split("/");
    for(var i=0;i<a.length;i++)
        if(a[i].slice(0,1)==":" && a[i].slice(-1)=="?") a[i]="";
    
    var filtered = a.filter(function (el,i) {        
        return (el != "")||(i<=1);
    });

    return filtered.join("/");
}

</script>


<template>
    <template v-if="props.parentid==0">
        <template v-for="item in items">
            <div class="rootmenuitem" v-if="item.parentid==props.parentid && item.visible">
    
                <div class="nav-item" :class="{ 'dropdown': item.children>0 }" v-if="item.parentid==props.parentid && item.visible">


                    

                    <a class="nav-link rootitem" v-if="item.path.indexOf('://')>0"
                        :href="item.path" :target="item.name"
                        :title="t(props.path + item.name.toLowerCase() + '.caption')"                        
                        :class="item.enabled == false ? 'disabled': (parentid>0?'dropdown-item':'')"               
                        >
                        <i v-if="item.icon" :class="'bi bi-'+item.icon" />
                        <template v-else>
                            {{ $t(props.path + item.name.toLowerCase()+'.caption') }}
                        </template>
                    </a>


                    <router-link v-else-if="item.path && item.children == 0"                 
                        class="nav-link rootitem" :class="item.enabled == false ? 'disabled': (parentid>0?'dropdown-item':'')"              
                        :title="t(props.path + item.name.toLowerCase())"                                   
                        :to="sanitize(item.path)"
                        >

                        <hr v-if="item.name == '-'" class="dropdown-divider">
                        <template v-else>
                            <i v-if="item.icon" :class="'bi bi-'+item.icon" />
                            <template v-else>
                                {{ $t(props.path + item.name.toLowerCase()) }}
                            </template>
                        </template>
                    </router-link>
               

        
                    <template v-if="item.children > 0">

                        <a class="nav-link rootitem"
                            data-bs-toggle="dropdown"
                            :title="t(props.path + item.name.toLowerCase() + '.caption')"                        
                            :class="item.enabled == false ? 'disabled': (parentid>0?'dropdown-item':'')"               
                            >

                            <i v-if="item.icon" :class="'bi bi-'+item.icon" />
                            <template v-else>
                                {{ $t(props.path + item.name.toLowerCase()+'.caption') }}
                            </template>
                        </a>

                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <MenuItemComponent :items="items" :parentid="item.id" :path="props.path+item.name.toLowerCase()+'.'"/>
                        </div>             
                    </template>
             
                </div>
            </div>
        </template>
        <div class="rootmenuitem">
            <LocaleChangerComponent v-if="parentid==0" />  
        </div>

    </template>
 
    <template v-else v-for="item in items">

        <div class="nav-item" :class="{ 'dropdown': item.children>0 }" v-if="item.parentid==props.parentid && item.visible">


            <a class="nav-link rootitem" v-if="item.path.indexOf('://')>0"
                :href="item.path" :target="item.name"
                :title="t(props.path + item.name.toLowerCase() + '.caption')"                        
                :class="item.enabled == false ? 'disabled': (parentid>0?'dropdown-item':'')"               
                >
                <i v-if="item.icon" :class="'bi bi-'+item.icon" />
                <template v-else>
                    {{ $t(props.path + item.name.toLowerCase()+'.caption') }}
                </template>
            </a>

            <router-link v-else-if="item.path && item.children == 0"                 
                class="nav-link " :class="item.enabled == false ? 'disabled': (parentid>0?'dropdown-item':'')"              
                :title="t(props.path + item.name.toLowerCase())"                
                :to="sanitize(item.path)"
                >

                <hr v-if="item.name == '-'" class="dropdown-divider">
                <template v-else>
                    <i v-if="item.icon" :class="'bi bi-'+item.icon" />
                    <template v-else>
                        {{ $t(props.path + item.name.toLowerCase()) }}
                    </template>
                </template>
            </router-link>



            <a class="nav-link dropdown-menu"  v-if="item.children > 0"                
                data-bs-toggle="dropdown"
                :title="t(props.path + item.name.toLowerCase() + '.caption')"
                :class="item.enabled == false ? 'disabled': (parentid>0?'dropdown-item':'')"               
                >

                <i v-if="item.icon" :class="'bi bi-'+item.icon" />
                <template v-else>
                    {{ $t(props.path + item.name.toLowerCase()+'.caption') }}
                </template>
            </a>

            <template v-if="item.children > 0">
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <MenuItemComponent :items="items" :parentid="item.id" :path="props.path+item.name.toLowerCase()+'.'"/>
                </div>             
            </template>
        </div>
    </template>
  
</template>

<style>
 


@media (max-width: 990px)  {
    .rootitem  {
        padding :4px;        
        padding-top : 5px;        
   }

}


@media (min-width: 991px)  {
    .rootitem  {
        padding :8px;
        
        padding-top : 7px;
        font-size: 1.1em;    
    }
} 
.rootmenuitem {
    float : left;
    
}

a.rootitem ,
a.dropdown-item {
    color : #444444;
}
a.rootitem:hover ,
a.dropdown-item:hover {
    color : #004b93;
}

</style>