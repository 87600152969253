import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore, useStatusStore } from '@/stores';
import { HomeView, NotFoundView} from '@/views';
import { activateHints } from '@/helpers';
import { nextTick } from 'vue'
 
export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    linkActiveClass: 'active',    
    routes : [
        { path: '/:pathMatch(.*)*', name: '404', component: NotFoundView, meta : {requiresAuth: false, enabled:true}, },
        { path: '/Home',  name: "Home", component: HomeView, meta : {requiresAuth: false, enabled:true},},
        { path: '/Login/:code?',  name: "Login", component: () => import("@/views/LoginView.vue"), meta : {requiresAuth: false, enabled:true},},

        { path: '/CreateAccount',  name: "CreateAccount",component: () => import("@/views/CreateAccountView.vue"), meta : {requiresAuth: false, enabled:true},},
        { path: '/ResetPassword/:code?',  name: "ResetPassword",component: () => import("@/views/ResetPasswordView.vue"), meta : {requiresAuth: false, enabled:true},},

        
        
        { path: '/Devices',  name: "Devices",component: () => import("@/views/DevicesView.vue"), meta : {requiresAuth: true, enabled:false},},
//        { path: '/Help',  name: "Help",component: () => import("@/views/HelpView.vue"), meta : {requiresAuth: false, enabled:true},},
        { path: '/Device/:id/:tab?',  name: "Device",component: () => import("@/views/DeviceView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/LinkDevice',  name: "LinkDevice",component: () => import("@/views/LinkDeviceView.vue"), meta : {requiresAuth: true, enabled:false},},
        
        
        // ACCOUNT
        { path: '/Account/Logout',  name: "account/Logout",component: () => import("@/views/Account/LogoutView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/Account/Account/:tab?',  name: "account/Account",component: () => import("@/views/Account/AccountView.vue"), meta : {requiresAuth: true, enabled:false},},

        // CMS
        { path: '/cms/Devices',  name : "cms/Devices", component: () => import("@/views/cms/DevicesView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/cms/Device/:id?', name : "cms/Device", component: () => import("@/views/cms/DeviceView.vue"), meta : {requiresAuth: true, enabled:false},},

        { path: '/cms/Users',  name: "cms/Users",component: () => import("@/views/cms/UsersView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/cms/User/:id?',  name: "cms/User",component: () => import("@/views/cms/UserView.vue"), meta : {requiresAuth: true, enabled:false},},

        { path: '/cms/Texts',  name: "cms/Texts",component: () => import("@/views/cms/TextsView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/cms/Text/:id?',  name: "cms/Text",component: () => import("@/views/cms/TextView.vue"), meta : {requiresAuth: true, enabled:false},},

        { path: '/cms/Menus',  name: "cms/Menus",component: () => import("@/views/cms/MenusView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/cms/Menu/:id?',  name: "cms/Menu",component: () => import("@/views/cms/MenuView.vue"), meta : {requiresAuth: true, enabled:false},},

        { path: '/cms/Notifications',  name: "cms/Notifications",component: () => import("@/views/cms/NotificationsView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/cms/Notification/:id?',  name: "cms/Notification",component: () => import("@/views/cms/NotificationView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/cms/SystemInfo',  name: "cms/SystemInfo",component: () => import("@/views/cms/SystemInfoView.vue"), meta : {requiresAuth: true, enabled:false},},
        

        // ADMIN
        { path: '/admin/Users',  name: "admin/Users",component: () => import("@/views/admin/AdminUsersView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/admin/User/:id',  name: "admin/User",component: () => import("@/views/admin/AdminUserView.vue"), meta : {requiresAuth: true, enabled:false},},

        { path: '/admin/Devices',  name: "admin/Devices",component: () => import("@/views/admin/AdminDevicesView.vue"), meta : {requiresAuth: true, enabled:false},},
        { path: '/admin/Device/:id',  name: "admin/Device",component: () => import("@/views/admin/AdminDeviceView.vue"), meta : {requiresAuth: true, enabled:false},},

        { path: '/admin/SystemInfo',  name: "admin/SystemInfo",component: () => import("@/views/admin/SystemInfoView.vue"), meta : {requiresAuth: true, enabled:false},},
    ], 
});

const DEFAULT_TITLE = import.meta.env.VITE_DEFAULT_TITLE;
const appversion = __APP_VERSION__

var items = JSON.parse(localStorage.getItem("routerItems"))?? [];
if(items && (items.length))fillRoutes(items);


function resetRoutes() {
    var routes = router.getRoutes();
    for (var i = 0; i < routes.length; i++) {
        routes[i].meta.enabled = (routes[i].name=="404")||(routes[i].name=="Login")||(routes[i].name=="Home")||(routes[i].meta.requiresAuth==false);
    }
}

router.beforeEach(async (to, from) => {
    const authRequired = to.meta.requiresAuth || to.meta.roles;
    const auth = useAuthStore();
    const status = useStatusStore();
    
    // Kill Vervelende plakkende Hints....
    activateHints();

    if(from.path!= to.path)  status.stopLoading();
        

    if(to.path=="/"){
        return auth.loggedIn()?'/Devices':'/Login';
    }

    if((to.name==undefined)||(!to.meta)) {
        return '/404';    
    }

    if((to.meta.requiresAuth)&&((!auth.user)||(!auth.user.hasOwnProperty("id"))||(auth.user.id<=0))) {
        auth.logout();
//        console.log("UNAUTH: ", to);
      //  resetRoutes();
        return '/Login';
    }

    if(!to.meta.enabled) {
        //console.log(to);
        //auth.logout();
        //resetRoutes();
        return 'Help';
    }
});

router.afterEach((to, from) => {    
    nextTick(() => {         
        if((to)&&(to.name)){
            var a= to.name.split("/");
            document.title = DEFAULT_TITLE +(a.length? " | "+a[a.length-1] : "");
        }        
    });
});

export function fillRoutes(items) {    
    var routes = router.getRoutes();

    for (var i = 0; i < routes.length; i++) {
        routes[i].meta.enabled = (routes[i].name=="404")||(routes[i].name=="Home")||(routes[i].meta.requiresAuth==false);
          
        for (var j = 0; j < items.length; j++) {            
            if(routes[i].name==items[j].routename) {
                routes[i].meta.menuid = items[j].id;
                routes[i].meta.enabled = items[j].enabled;
                routes[i].meta.roles = items[j].roles;
                routes[i].path = items[j].path;

          //      console.log(routes[i].name, routes[i].meta.enabled);
            }
        }        
    }

    localStorage.setItem("routerItems", JSON.stringify(items));                 
}